import { Environment } from './model/environment.model';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://apim-psp-backend-test.azure-api.net/api',
  keycloakUrl: 'https://test.log.wien/auth/',
  keycloakRealm: 'admin',
  keycloakClient: 'client-individualkundenportal-admin-public',
  baseUrl: 'https://ikpberaterfrontendtest.kinsta.cloud',
  staticContentUrl: 'https://static.wienenergie.at/projects/premiumserviceportal/config',
  impersonationUrl: 'https://test.log.wien/kunden-service-app/',
  impersonationTarget: 'KGUcollaborate',
  impersonationTargetUrl: 'https://test-unternehmen.wienenergie.at/home',
  impersonationCollaborationUrl:
    'https://test.log.wien/rest/logwien/admin/users/{userId}/partners/{partnerName}/services/{serviceName}/impersonation',
  impersonationLogWienService: 'we-ikp',
  impersonationLogWienPartner: 'wien-energie',
  isRegistrationEnabled: false,
};
